import React from "react";
import LeftRightLayout from "../../Layouts/left-right-layout/LeftRightLayout";
import {
  aiDevelopmentContent,
  aiDevelopmentGridContent,
} from "../../../Common/Constant/itemLists";
import MyGrid from "../../Layouts/Grid/MyGrid";
import PageIntroHeading from "../../Layouts/PageIntroHeading/PageIntroHeading";
import Conclusion from "../../Layouts/Conclusion/Conclusion";
import "./aiDevelopment.scss";
const AiDevelopment = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"AI Solutions"}
        text={
          "In today's fast-paced digital landscape, staying ahead of the competition requires innovation and efficiency. Artificial Intelligence (AI) is the key to unlocking the full potential of your website/apps. Our AI Solution Development Service is designed to empower your website with the latest advancements in AI technology, enhancing user experiences, automating tasks, and boosting overall performance."
        }
      />
      <LeftRightLayout
        list={aiDevelopmentContent}
        heading={"Our AI Solution Development Services:"}
      />
      <MyGrid
        list={aiDevelopmentGridContent}
        gridHeading={"Why Choose Our AI Solutions"}
      />
      <Conclusion
        heading={"Conclusion"}
        text={
          "Elevate your website to new heights with our AI Solution Development Service. Whether you want to enhance user engagement, automate tasks, or gain valuable insights from data, our team of AI experts is ready to turn your vision into reality. Contact us today to discuss how AI can transform your website and drive your business forward."
        }
      />
    </div>
  );
};

export default AiDevelopment;
