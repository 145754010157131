import React from "react";
import Carousel from "react-multi-carousel";
import "./myCarousel.scss";

const MyCarousel = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  return (
    <div>
      <div className="carousel-heading">
        <h2>{props.heading}</h2>
      </div>
      <Carousel
        arrows={true}
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        showDots={false}
        transitionDuration={500}
        infinite={true}
      >
        {props.list &&
          props.list?.map((e) => {
            return (
              <div
                key={Math.random().toString(36).slice(2)}
                className="carousel-div"
              >
                {e.image ? (
                  <div className="carousel-div-img">
                    <img
                      width={"160px"}
                      height={"160px"}
                      src={e.image}
                      alt=""
                      srcSet=""
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="carousel-div-text">
                  {e.heading ? <h2>{e.heading}</h2> : <></>}
                  {e.subHeading ? <text>{e.subHeading}</text> : <></>}
                  {e.text ? <p>{e.text}</p> : <></>}
                </div>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};

export default MyCarousel;
