export const products = [
  {
    heading: "Innovations",
    subHeading: "AIO Switch, Smart AI Camera",
    path: "innovation",
    products: [
      {
        heading: "AIO Switch",
        subHeading: "Smart Infrastructure",
        path: "aio-switch",
        products: [],
      },
      {
        heading: "Smart AI Camera",
        subHeading: "Face Recognition",
        path: "smart-camera",
        products: [],
      },
    ],
  },
  {
    heading: "Management",
    subHeading: "Smart Employee",
    path: "management",
    products: [
      {
        heading: "Face Recognition",
        subHeading: "Face Recognition / Smart Employee",
        path: "face-recognition",
        products: [],
      },
    ],
  },
  {
    heading: "Hardware Solutions",
    subHeading: "Kiosk, Podium",
    path: "hardware-solution",
    products: [
      {
        heading: "Kiosk",
        subHeading: "Kiossk Sub heading",
        path: "kiosk",
        products: [
          {
            heading: "Advertisment",
          },
          { heading: "Token Management" },
        ],
      },
      {
        heading: "Podium",
        subHeading: "podium sub",
        path: "podium",
        products: [],
      },
    ],
  },
];

export const services = [
  {
    heading: "Web Development",
    path: "web-development",
  },
  { heading: "Mobile App Development", path: "mobile-app-development" },
  { heading: "AI Solution Development", path: "ai-solution-development" },
  { heading: "IOT Solution Development", path: "iot-solution-development" },
  { heading: "Mechanical Support Service", path: "mechanical-support-service" },
];

export const aioSwitchContent = [
  {
    heading: "Remote Control",
    subHeading: "",
    text: "Our Smart Infrastructure App enables you to control a wide range of electric gadgets remotely. Whether you're at home, at the office, or on any other place, you can easily manage your devices from your smartphone.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Energy Efficiency",
    subHeading: "",
    text: "Save on your energy bills by optimizing the usage of your appliances. You can schedule devices to turn on or off at specific times, set temperature limits, or even receive alerts when you've forgotten to switch something off.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Data Analytics",
    subHeading: "",
    text: "Gain insights into your energy consumption patterns and usage habits through our app's data analytics. This information can help you make informed decisions to further optimize your lifestyle.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const aioSwitchGridContent = [
  {
    heading: "Expertise",
    subHeading: "",
    text: "Our team of experts has extensive experience in smart infrastructure technology.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Reliability",
    subHeading: "",
    text: "Count on us for a stable and secure app service that you can trust.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Customer Support",
    subHeading: "",
    text: "We offer excellent customer support to assist you every step of the way.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Constant Innovation",
    subHeading: "",
    text: "We are committed to staying at the forefront of smart infrastructure technology, ensuring you have access to the latest advancements.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const aioSwitchCarousel = [
  {
    heading: "Installation",
    subHeading: "",
    text: "You can easily set up the necessary infrastructure and devices in your home or office.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "App Setup",
    subHeading: "",
    text: "Download and install our Smart Infrastructure App on your smartphone or tablet. Connect it to your home internet/Wi-Fi network.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Device Pairing",
    subHeading: "",
    text: "Pair your electric gadgets and appliances with the app, following simple instructions provided.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Customization",
    subHeading: "",
    text: "Customize your settings, schedules, and automation rules to fit your lifestyle.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Enjoy Smart Living",
    subHeading: "",
    text: "Start enjoying the benefits of smart living, from convenience and energy savings to enhanced security and peace of mind.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const smartCameraContent = [
  {
    heading: "Indoor Cameras",
    subHeading: "",
    text: "Perfect for monitoring the interior of your home or office.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Outdoor Cameras",
    subHeading: "",
    text: "Built to withstand the elements and protect your property's perimeter.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Wireless Cameras",
    subHeading: "",
    text: "Enjoy the flexibility of wireless installation for hassle-free setup.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const smartCameraGridContent = [
  {
    heading: "AI-Powered Intelligence",
    subHeading: "",
    text: "Our cameras are equipped with advanced artificial intelligence algorithms that can distinguish between people & objects, reducing false alarms and providing accurate notifications.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "24/7 Monitoring",
    subHeading: "",
    text: "Enjoy round-the-clock surveillance with our Smart AI Camera Service. Rest easy knowing that your property is under constant watch, even when you're away.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Remote Access",
    subHeading: "",
    text: "Access live video feeds and recordings from your cameras anytime, anywhere. Stay connected to your property and loved ones no matter where life takes you.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Motion Detection",
    subHeading: "",
    text: "Receive instant alerts on your smartphone or computer when suspicious activity is detected. Our AI can differentiate between normal movements and potential threats.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cloud Storage",
    subHeading: "",
    text: "Say goodbye to the hassle of managing physical storage devices. Your footage is securely stored in the cloud, accessible whenever you need it.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Easy Installation",
    subHeading: "",
    text: "Our cameras are designed for simple DIY installation, with step-by-step instructions. No technical expertise required.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Privacy Protection",
    subHeading: "",
    text: "We take your privacy seriously. Our cameras are equipped with features like privacy zones to ensure your personal space remains private.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Compatibility",
    subHeading: "",
    text: "Our Smart AI Camera Service works seamlessly with a wide range of devices and platforms, including iOS, Android, and web browsers.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];
export const smartCameraCarousel = [
  {
    heading: "Expertise",
    subHeading: "",
    text: "Our team of experts has extensive experience in developing and implementing facial recognition systems for various industries.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Data Privacy",
    subHeading: "",
    text: "We prioritize data privacy and ensure compliance with relevant regulations, to protect user information.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Reliability",
    subHeading: "",
    text: "Our system is built for high availability, ensuring uninterrupted service for your website.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Seamless Integration",
    subHeading: "",
    text: "We work closely with your team to seamlessly integrate our Face Recognition System to match your needs.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const FaceRecognitionContent = [
  {
    heading: "Security",
    subHeading: "",
    text: "Face recognition adds an additional layer of security for you. Users can log in, access sensitive information, or perform secure transactions with their face as a unique identifier.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "User-Friendly",
    subHeading: "",
    text: "Say goodbye to password-related woes. Our system simplifies user authentication, making it as easy as looking at the camera. Users can access their accounts effortlessly, reducing friction and increasing user satisfaction.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Multi-Factor Authentication",
    subHeading: "",
    text: "Combine facial recognition with other authentication methods like passwords or PINs for enhanced security. This multi-factor approach adds an extra safeguard against unauthorized access.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Customization",
    subHeading: "",
    text: "Tailor the face recognition system to suit your needs. Adjust sensitivity levels, define access permissions, and integrate them seamlessly into your existing user authentication processes.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Real-time Monitoring",
    subHeading: "",
    text: "Gain real-time insights into user activities. Our system can track and log user interactions, helping you identify and respond to suspicious behavior promptly.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Scalability",
    subHeading: "",
    text: "Whether you have a small or a large-scale platform, our Face Recognition System Service can scale to accommodate your needs, ensuring reliable performance per your needs.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const FaceRecognitionGridContent = [
  {
    heading: "User Authentication",
    subHeading: "",
    text: "Replace traditional login methods with facial recognition for a more secure and user-friendly experience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Secure Transactions",
    subHeading: "",
    text: "Ensure secure financial transactions by verifying the user's identity through facial recognition.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Access Control",
    subHeading: "",
    text: "Restrict access to specific website sections or content based on user authentication through facial recognition.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Visitor Tracking",
    subHeading: "",
    text: "Implement face recognition for tracking and analyzing user demographics, behaviors, and preferences to improve marketing and user experience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];
export const FaceRecognitionCarousel = [
  {
    heading: "Expertise",
    subHeading: "",
    text: "Our team of experts has extensive experience in developing and implementing facial recognition systems for various industries.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Data Privacy",
    subHeading: "",
    text: "We prioritize data privacy and ensure compliance with relevant regulations, to protect user information.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Reliability",
    subHeading: "",
    text: "Our system is built for high availability, ensuring uninterrupted service for your website.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Seamless Integration",
    subHeading: "",
    text: "We work closely with your team to seamlessly integrate our Face Recognition System to match your needs.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const kioskContent = [
  {
    heading: "Customization",
    subHeading: "",
    text: "Tailor the kiosk interface to match your brand's identity, ensuring a seamless and immersive user experience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Interactive Touch Screen",
    subHeading: "",
    text: "Users can easily navigate through menus, select options, and complete transactions with a user-friendly touch screen.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Information Access",
    subHeading: "",
    text: "Provide customers with access to valuable information.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Self-Service Check-In",
    subHeading: "",
    text: "Speed up check-in processes for events, appointments, or flights, reducing long queues and wait times.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Digital Signage",
    subHeading: "",
    text: "Utilize the kiosk as a dynamic advertising platform to promote special offers, announcements, and brand messages.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Accessibility",
    subHeading: "",
    text: "Ensure inclusivity by offering features like screen readers, adjustable text sizes, and language options for diverse user needs.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Data Collection",
    subHeading: "",
    text: "Gather valuable customer data and feedback to improve your services and tailor your offerings to customer preferences.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const kioskGridContent = [
  {
    heading: "Enhanced Customer Experience",
    subHeading: "",
    text: "Users can access information and complete tasks quickly and conveniently, leading to higher customer satisfaction.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cost Savings",
    subHeading: "",
    text: "Reduce labor costs by automating routine processes and minimizing the need for additional staff.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Increased Efficiency",
    subHeading: "",
    text: "Streamline operations and reduce wait times, resulting in improved service delivery and happier customers.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Boosted Sales",
    subHeading: "",
    text: "By offering self-service payment options and promotions, you can increase sales and upsell opportunities.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Data-Driven Decisions",
    subHeading: "",
    text: "Gain insights into user behavior and preferences, helping you make informed business decisions.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "24/7 Availability",
    subHeading: "",
    text: "Kiosks are available round the clock, providing customers with access to your services at their convenience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const kioskCarousel = [
  {
    heading: "Retail",
    subHeading: "",
    text: "Enhance the shopping experience, provide product information, and process payments seamlessly.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Hospitality",
    subHeading: "",
    text: "Expedite check-ins and provide guests with information about amenities and local attractions.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Healthcare",
    subHeading: "",
    text: "Simplify appointment scheduling, offer self-check-in, and provide patients with health information.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Events",
    subHeading: "",
    text: "Speed up the registration process for conferences, concerts, and trade shows.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Education",
    subHeading: "",
    text: "Offer self-service information kiosks for campus maps, class schedules, and event announcements.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const podiumContent = [
  {
    heading: "Podiums",
    subHeading: "",
    text: "Our podiums are designed to enhance your stage presence. Crafted from high-quality materials and featuring advanced built-in technology, they offer ergonomic design for speakers and come with integrated sound systems, touchscreen displays, and connectivity options.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Microphones and Audio Systems",
    subHeading: "",
    text: "Clear and crisp audio is essential for effective communication. We provide a wide selection of microphones, including wireless, lapel, and handheld options. Our audio systems deliver superior sound quality, ensuring that your message is heard loud and clear.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Visual Display Solutions",
    subHeading: "",
    text: "Engage your audience with stunning visual displays. We offer a range of screens, projectors, and interactive whiteboards to cater to your presentation needs. Our technicians can also assist with installation and setup.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "AV Accessories",
    subHeading: "",
    text: "Elevate your setup with our AV accessories, including cables, adapters, mounts, and stands. We offer a variety of options to optimize your audio-visual configuration.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Technical Support and Maintenance",
    subHeading: "",
    text: "Our commitment doesn't end with the sale. We provide reliable technical support and maintenance services to ensure your hardware operates at its best. Our skilled technicians are available for troubleshooting and repairs.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const podiumGridContent = [
  {
    heading: "Quality Assurance",
    subHeading: "",
    text: "We source and provide only top-quality hardware to meet your AV needs.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Expert Advice",
    subHeading: "",
    text: "Our experienced team can help you select the best equipment tailored to your requirements.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Installation Services",
    subHeading: "",
    text: "We offer professional installation and setup to ensure seamless integration.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Customer Support",
    subHeading: "",
    text: "Our dedicated support team is available to assist you with any queries or issues.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Custom Solutions",
    subHeading: "",
    text: "We understand that every event and venue is unique. We can provide customized solutions to meet your specific needs.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Competitive Pricing",
    subHeading: "",
    text: "We offer competitive prices without compromising on quality.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const webDevelopmentContent = [
  {
    heading: "Custom Website Design",
    subHeading: "",
    text: "Our team of skilled designers and developers will work closely with you to create a unique and eye-catching website tailored to your brand identity. We ensure that your website not only looks great but also provides an exceptional user experience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Responsive Design",
    subHeading: "",
    text: "In today's mobile-driven world, having a responsive website is crucial. We ensure that your website is accessible and user-friendly on all devices, from smartphones and tablets to desktop computers.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "E-Commerce Solutions",
    subHeading: "",
    text: "If you're in the business of selling products online, we can develop a secure and efficient e-commerce platform that will help you reach a wider audience and boost your online sales.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Content Management Systems (CMS)",
    subHeading: "",
    text: "We specialize in creating websites using popular CMS platforms like WordPress, Joomla, and Drupal, making it easy for you to update and manage your website's content without technical expertise.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Web Application Development",
    subHeading: "",
    text: "From customer portals to complex web applications, we have the skills and experience to develop custom web solutions that streamline your business operations and enhance user engagement.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Website Maintenance and Support",
    subHeading: "",
    text: "We offer ongoing maintenance and support services to keep your website running smoothly. Whether it's security updates, content updates, or troubleshooting, we've got you covered.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const webDevelopmentGridContent = [
  {
    heading: "Experienced Team",
    subHeading: "",
    text: "Our team of web developers and designers has years of experience in the industry, ensuring that your project is in capable hands.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Tailored Solutions",
    subHeading: "",
    text: "We understand that every business is unique, which is why we tailor our web development services to meet your specific needs and goals.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Proven Track Record",
    subHeading: "",
    text: "We have a history of delivering successful web projects for clients across various industries. Check out our portfolio to see our work in action.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Affordable Pricing",
    subHeading: "",
    text: "We offer competitive pricing without compromising on quality. Our goal is to provide value for your investment.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const mobileDevelopmentContent = [
  {
    heading: "iOS App Development",
    subHeading: "",
    text: "We excel in creating sleek and intuitive iOS applications that run seamlessly on all Apple devices, ensuring a top-notch user experience.",
    image:
      "https://wepik.com/api/image/ai/9a0f8309-f3a9-47e0-8665-56ca9ccb8c98",
  },
  {
    heading: "Android App Development",
    subHeading: "",
    text: "Our Android app development team harnesses the power of the Android platform to build robust and scalable applications that cater to a wide audience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cross-Platform App Development",
    subHeading: "",
    text: "Reach a broader user base with cross-platform apps that work on both iOS and Android devices, saving you time and resources.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const mobileDevelopmentCarousel = [
  {
    heading: "Consultation",
    subHeading: "",
    text: "We start by discussing your app idea, goals, and target audience to define the project scope.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Design",
    subHeading: "",
    text: "Our designers create wireframes and prototypes to visualize the app's look and feel.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Development",
    subHeading: "",
    text: "Our developers bring your app to life, using the latest coding practices and technologies.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Testing",
    subHeading: "",
    text: "We conduct thorough testing to ensure your app is bug-free and performs flawlessly.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Launch",
    subHeading: "",
    text: "Once your app is ready, we assist with its launch on app stores and help you with marketing strategies if needed.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Support and Maintenance",
    subHeading: "",
    text: "We provide ongoing support and maintenance to keep your app up-to-date and running smoothly.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const mobileDevelopmentGridContent = [
  {
    heading: "Experienced Team",
    subHeading: "",
    text: "Our dedicated team of developers, designers, and project managers boasts years of experience in the industry, ensuring your project is in capable hands.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Custom Solutions",
    subHeading: "",
    text: "We don't believe in one-size-fits-all solutions. We tailor our app development process to meet your unique requirements, ensuring a personalized experience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cutting-Edge Technology",
    subHeading: "",
    text: "We stay up to date with the latest trends and technologies in mobile app development to deliver modern, feature-rich applications.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "User-Centric Design",
    subHeading: "",
    text: "User experience is at the core of our app design philosophy. We create intuitive interfaces that keep users engaged and coming back for more.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Quality Assurance",
    subHeading: "",
    text: "Rigorous testing and quality assurance processes are integral to our development cycle, guaranteeing bug-free and reliable apps.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Timely Delivery",
    subHeading: "",
    text: "We understand the importance of meeting deadlines. You can trust us to deliver your app on time and within budget.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const aiDevelopmentContent = [
  {
    heading: "AI-Powered Chatbots",
    subHeading: "",
    text: "Improve customer support and engagement with intelligent chatbots that can answer inquiries, provide information, and even make recommendations 24/7.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Personalized Content Recommendation",
    subHeading: "",
    text: "Keep your visitors engaged by serving them personalized content, products, and recommendations based on their preferences and behavior.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Natural Language Processing (NLP)",
    subHeading: "",
    text: "Enable your website to understand and respond to user queries and comments with human-like comprehension, enhancing user interaction and satisfaction.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Predictive Analytics",
    subHeading: "",
    text: "Leverage AI to analyze user data, predict trends, and make data-driven decisions for your business strategy",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Image and Video Analysis",
    subHeading: "",
    text: "Enhance the visual aspects of your website by integrating AI for image and video recognition, categorization, and optimization.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "AI-Powered Search",
    subHeading: "",
    text: "Revolutionize search functionality on your website by implementing AI-enhanced search algorithms that deliver more accurate and relevant results.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];
export const aiDevelopmentGridContent = [
  {
    heading: "Tailored Solutions",
    subHeading: "",
    text: "We understand that every website is unique. Our team of AI experts will work closely with you to develop custom AI solutions that align with your specific goals and requirements.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cutting-Edge Technology",
    subHeading: "",
    text: "We stay at the forefront of AI advancements, ensuring that your website benefits from the latest breakthroughs in AI research.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Scalability",
    subHeading: "",
    text: "Our AI solutions are designed to grow with your business, ensuring that they remain effective as your website and user base expand.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Data Privacy",
    subHeading: "",
    text: "We prioritize data security and privacy, implementing robust measures to protect user data and ensure compliance with data protection regulations.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const iotDevelopmentContent = [
  {
    heading: "Customized Solutions",
    subHeading: "",
    text: "We understand that every business is different. That's why we offer tailor-made IoT solutions designed to address your specific requirements, whether you're in manufacturing, healthcare, logistics, agriculture, or any other industry.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "End-to-End Expertise",
    subHeading: "",
    text: "Our team of IoT experts covers every aspect of solution development, from hardware design and sensor integration to data analytics and software development. We provide a comprehensive and seamless experience.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Scalability",
    subHeading: "",
    text: "Our solutions are built to grow with your business. Whether you need to connect a handful of devices or manage a massive IoT ecosystem, we have the scalability to meet your demands.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Security",
    subHeading: "",
    text: "Security is paramount in IoT, and we take it seriously. Our solutions incorporate robust security measures to protect your data and devices from potential threats.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Real-time Insights",
    subHeading: "",
    text: "Gain valuable real-time insights into your operations with our IoT solutions. Monitor performance, predict maintenance needs, and make data-driven decisions with ease.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cost-Effective",
    subHeading: "",
    text: "We optimize your IoT deployment to ensure cost-effectiveness. Our solutions help you reduce operational costs, enhance efficiency, and boost your bottom line.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Seamless Integration",
    subHeading: "",
    text: "We make sure our IoT solutions seamlessly integrate with your existing systems and technologies, minimizing disruption and ensuring a smooth transition.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const iotDevelopmentGridContent = [
  {
    heading: "Consultation",
    subHeading: "",
    text: "We start by understanding your business goals, challenges, and specific IoT needs. This initial consultation helps us define the scope of your project.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Design",
    subHeading: "",
    text: "Our team designs a comprehensive IoT solution, considering hardware, sensors, connectivity, and software components. We focus on usability, scalability, and security.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Development",
    subHeading: "",
    text: "We build the IoT infrastructure, develop custom software, and integrate sensors and devices into your network. Rigorous testing ensures reliability.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Deployment",
    subHeading: "",
    text: "Once your IoT solution is ready, we handle the deployment and integration of your existing systems. We ensure a smooth transition and provide training as needed.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Monitoring and Maintenance",
    subHeading: "",
    text: "We continuously monitor the performance of your IoT ecosystem, making necessary updates and providing ongoing support to keep your operations running smoothly.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const mechanicalSupportContent = [
  {
    heading: "Equipment Maintenance",
    subHeading: "",
    text: "We offer proactive maintenance services to keep your machinery and mechanical systems in optimal condition. Our team of skilled technicians conducts regular inspections, lubrication, and minor repairs to prevent breakdowns and extend the lifespan of your equipment.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Emergency Repairs",
    subHeading: "",
    text: "Mechanical failures can happen unexpectedly, leading to costly downtime. Our rapid-response team is available 24/7 to address emergency repair needs promptly. We aim to minimize disruptions to your operations and get you back on track as quickly as possible.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Technical Consultation",
    subHeading: "",
    text: "Unsure about the right equipment or systems for your specific needs? Our experienced engineers and consultants can guide you through the selection process, helping you make informed decisions that align with your budget and goals.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Upgrades and Retrofits",
    subHeading: "",
    text: "Stay competitive by upgrading your existing equipment or systems. We specialize in retrofitting older machinery with modern components to enhance performance, energy efficiency, and safety.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Preventive Maintenance Plans",
    subHeading: "",
    text: "Our customized preventive maintenance plans are designed to fit your unique requirements. Regularly scheduled maintenance not only reduces downtime but also helps you avoid costly repairs in the long run.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Spare Parts Supply",
    subHeading: "",
    text: "We maintain an extensive inventory of high-quality spare parts for various mechanical systems. Our streamlined supply chain ensures you get the parts you need when you need them.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Training and Education",
    subHeading: "",
    text: "Knowledge is key to optimizing the performance of your mechanical systems. We offer training programs for your staff to ensure they have the skills and knowledge necessary to operate and maintain equipment safely and effectively.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const mechanicalSupportGridContent = [
  {
    heading: "Expertise",
    subHeading: "",
    text: "Our team consists of highly skilled technicians, engineers, and consultants with years of experience in the mechanical industry.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Reliability",
    subHeading: "",
    text: "We understand that your business depends on reliable machinery. You can count on us for timely and efficient service.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Customized Solutions",
    subHeading: "",
    text: "We tailor our services to meet your specific needs, ensuring you get the most value out of our partnership.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "24/7 Support",
    subHeading: "",
    text: "Mechanical issues don't wait for business hours, and neither do we. Our emergency support is available round the clock.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cutting-Edge Technology",
    subHeading: "",
    text: "We stay up to date with the latest advancements in mechanical systems to provide you with state-of-the-art solutions.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Cost-Effective",
    subHeading: "",
    text: "Our services are designed to help you reduce operational costs and maximize the return on your investment.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];

export const happyCustomersImages = [
  {
    src: "https://i.pinimg.com/originals/6a/72/4b/6a724b9501764fd83a4abcd37b58144d.png",
    name: "Railways",
  },
  {
    src: "https://download.logo.wine/logo/Indian_Air_Force/Indian_Air_Force-Logo.wine.png",
    name: "Air Force",
  },
  {
    src: "https://i.pinimg.com/originals/6a/72/4b/6a724b9501764fd83a4abcd37b58144d.png",
    name: "Railways",
  },
  {
    src: "https://i.pinimg.com/originals/6a/72/4b/6a724b9501764fd83a4abcd37b58144d.png",
    name: "Railways",
  },
  {
    src: "https://i.pinimg.com/originals/6a/72/4b/6a724b9501764fd83a4abcd37b58144d.png",
    name: "Railways",
  },
  {
    src: "https://i.pinimg.com/originals/6a/72/4b/6a724b9501764fd83a4abcd37b58144d.png",
    name: "Railways",
  },
];

export const aboutUsContent = [
  {
    heading: "Our Company",
    subHeading: "",
    text: "Welcome to ndm.ai, your trusted partner in providing comprehensive solutions for the Internet of Things (IoT) and software development. With a passion for innovation and a commitment to excellence, we bring together cutting-edge technology and expert professionals to deliver the best-in-className solutions.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Our Clients",
    subHeading: "",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem molestias doloribus obcaecati illum accusantium esse iure magni totam veniam sit velit fugiat modi exercitationem maiores quaerat pariatur, itaque quae ipsam?",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Our Mission",
    subHeading: "",
    text: "Our mission is to empower businesses and organizations to harness the potential of IoT and software technologies to enhance efficiency, productivity, and customer experiences. We strive to be a true partner, guiding our clients through every step of their digital transformation journey.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
  {
    heading: "Why Choose Us",
    subHeading: "At ndm.ai, we stand out with:",
    text: "Extensive expertise in IoT and software development, Customized solutions tailored to your unique needs, A dedicated team of skilled professionals, Proven track record of successful projects, Commitment to quality, innovation, and customer satisfaction.",
    image:
      "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
  },
];
