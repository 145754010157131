import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/Index";
import AboutUs from "./AboutUs/Index";
import Services from "./Services/Index";
import AIOSwitch from "./Product/Innovation/AIO-Switch/AIOSwitch";
import SmartCamera from "./Product/Innovation/Smart-Camera/SmartCamera";
import Kiosk from "./Product/Hardware-Solution/Kiosk/Kiosk";
import Podium from "./Product/Hardware-Solution/Podium/Podium";
import WebDevelopment from "./Services/Web-Development/WebDevelopment";
import MobileDevelopment from "./Services/Mobile-Development/MobileDevelopment";
import AiDevelopment from "./Services/AI-Development/AiDevelopment";
import IotDevelopment from "./Services/IOT-Development/IotDevelopment";
import MechanicalSupport from "./Services/MechanicalSupport/MechanicalSupport";
import FaceRecognition from "./Product/Management/Face-Recognition/FaceRecognition";

const Routing = (props) => {
  return (
    <div>
      <Routes>
        <Route
          path={"/"}
          element={<HomePage contactRef={props.contactRef} />}
        />
        <Route path={"/services"} element={<Services />} />
        <Route path={"/product/aio-switch"} element={<AIOSwitch />} />
        <Route path={"/product/smart-camera"} element={<SmartCamera />} />
        <Route path={"/product/face-recognition"} element={<FaceRecognition />} />
        <Route path={"/product/kiosk"} element={<Kiosk />} />
        <Route path={"/product/podium"} element={<Podium />} />
        <Route
          path={"/services/web-development"}
          element={<WebDevelopment />}
        />
        <Route
          path={"/services/mobile-app-development"}
          element={<MobileDevelopment />}
        />
        <Route
          path={"/services/ai-solution-development"}
          element={<AiDevelopment />}
        />
        <Route
          path={"/services/iot-solution-development"}
          element={<IotDevelopment />}
        />
        <Route
          path={"/services/mechanical-support-service"}
          element={<MechanicalSupport />}
        />
        <Route path={"/about"} element={<AboutUs />} />
      </Routes>
    </div>
  );
};

export default Routing;
