import React, { useEffect } from "react";
import { Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./homePage.scss";

const Services = () => {
  const imgOne = React.useRef();
  const imgTwo = React.useRef();

  //   useEffect(() => {
  //     gsap.registerPlugin(ScrollTrigger);
  //     gsap.to(imgOne.current, {
  //       scrollTrigger: {
  //         trigger: imgOne.current, // selector or element
  //         start: "top center", // [trigger] [scroller] positions
  //         end: "+=300", // [trigger] [scroller] positions
  //         // markers: true, // only during development!
  //         toggleActions: "restart pause reverse reverse",
  //         scrub: true,
  //       },
  //       delay: 1,
  //       x: 200,
  //       y: 0,
  //       ease: Power3.easeOut,
  //       opacity: 2,
  //     });

  //     gsap.registerPlugin(ScrollTrigger);
  //     gsap.to(imgTwo.current, {
  //       scrollTrigger: {
  //         trigger: imgTwo.current, // selector or element
  //         start: "top center", // [trigger] [scroller] positions
  //         end: "+=300", // [trigger] [scroller] positions
  //         // markers: true, // only during development!
  //         toggleActions: "restart pause reverse reverse",
  //         scrub: true,
  //       },
  //       delay: 1,
  //       x: 200,
  //       y: 0,
  //       ease: Power3.easeOut,
  //       opacity: 2,
  //     });
  //   }, []);

  return (
    <div className="services-wrapper">
      <h1 className="service-heading">Our Services</h1>
      <div className="services-container">
        <div className="edge">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height={"54"}
            width={"54"}
          >
            <path
              fill="#f9f9f940"
              d="M4 16H20V5H4V16ZM13 18V20H17V22H7V20H11V18H2.9918C2.44405 18 2 17.5511 2 16.9925V4.00748C2 3.45107 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44892 22 4.00748V16.9925C22 17.5489 21.5447 18 21.0082 18H13Z"
            ></path>
          </svg>

          <h2>AI on Edge IoT</h2>
          <p>Basic description about this vertical</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique
            porro omnis explicabo. Mollitia quae laboriosam autem assumenda
            quibusdam. Excepturi temporibus nobis saepe porro sequi debitis,
            quae consectetur id repudiandae cum.
          </p>
        </div>
        <div className="tech">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height={"54"}
            width={"54"}
          >
            <path
              fill="#f9f9f940"
              d="M9 4C10.1046 4 11 4.89543 11 6V12.8271C10.1058 12.1373 8.96602 11.7305 7.6644 11.5136L7.3356 13.4864C8.71622 13.7165 9.59743 14.1528 10.1402 14.7408C10.67 15.3147 11 16.167 11 17.5C11 18.8807 9.88071 20 8.5 20C7.11929 20 6 18.8807 6 17.5V17.1493C6.43007 17.2926 6.87634 17.4099 7.3356 17.4864L7.6644 15.5136C6.92149 15.3898 6.1752 15.1144 5.42909 14.7599C4.58157 14.3573 4 13.499 4 12.5C4 11.6653 4.20761 11.0085 4.55874 10.5257C4.90441 10.0504 5.4419 9.6703 6.24254 9.47014L7 9.28078V6C7 4.89543 7.89543 4 9 4ZM12 3.35418C11.2671 2.52376 10.1947 2 9 2C6.79086 2 5 3.79086 5 6V7.77422C4.14895 8.11644 3.45143 8.64785 2.94126 9.34933C2.29239 10.2415 2 11.3347 2 12.5C2 14.0652 2.79565 15.4367 4 16.2422V17.5C4 19.9853 6.01472 22 8.5 22C9.91363 22 11.175 21.3482 12 20.3287C12.825 21.3482 14.0864 22 15.5 22C17.9853 22 20 19.9853 20 17.5V16.2422C21.2044 15.4367 22 14.0652 22 12.5C22 11.3347 21.7076 10.2415 21.0587 9.34933C20.5486 8.64785 19.8511 8.11644 19 7.77422V6C19 3.79086 17.2091 2 15 2C13.8053 2 12.7329 2.52376 12 3.35418ZM18 17.1493V17.5C18 18.8807 16.8807 20 15.5 20C14.1193 20 13 18.8807 13 17.5C13 16.167 13.33 15.3147 13.8598 14.7408C14.4026 14.1528 15.2838 13.7165 16.6644 13.4864L16.3356 11.5136C15.034 11.7305 13.8942 12.1373 13 12.8271V6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6V9.28078L17.7575 9.47014C18.5581 9.6703 19.0956 10.0504 19.4413 10.5257C19.7924 11.0085 20 11.6653 20 12.5C20 13.499 19.4184 14.3573 18.5709 14.7599C17.8248 15.1144 17.0785 15.3898 16.3356 15.5136L16.6644 17.4864C17.1237 17.4099 17.5699 17.2926 18 17.1493Z"
            ></path>
          </svg>
          <h2>Solving Neccessities with Tech</h2>
          <p>Basic description about this vertical</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique
            porro omnis explicabo. Mollitia quae laboriosam autem assumenda
            quibusdam. Excepturi temporibus nobis saepe porro sequi debitis,
            quae consectetur id repudiandae cum.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
