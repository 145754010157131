import React from "react";
import PageIntroHeading from "../../../Layouts/PageIntroHeading/PageIntroHeading";
import LeftRightLayout from "../../../Layouts/left-right-layout/LeftRightLayout";
import MyGrid from "../../../Layouts/Grid/MyGrid";
import Conclusion from "../../../Layouts/Conclusion/Conclusion";
import { FaceRecognitionCarousel, FaceRecognitionContent, FaceRecognitionGridContent } from "../../../../Common/Constant/itemLists";

const FaceRecognition = () => {
  return (
    <div>
      <PageIntroHeading
        heading={
          "Face Recognition"
        }
        subHeading={"About Our Product -"}
        text={
          "In today's digital landscape, ensuring the security of online platforms while providing a seamless user experience is paramount. Face recognition technology has emerged as a powerful solution that can achieve both these goals. Our Face Recognition System is designed to offer cutting-edge facial recognition capabilities for you, bolstering security measures and enhancing user convenience."
        }
      />
      <LeftRightLayout list={FaceRecognitionContent} heading={"Key Features"} />
      <MyGrid
        list={FaceRecognitionGridContent}
        gridHeading={"Use Cases"}
      />
      <MyGrid
        list={FaceRecognitionCarousel}
        gridHeading={"Why Choose Our Service"}
        custom={true}
      />
      <Conclusion
        heading={"So, what are you waiting for"}
        text={
          "Enhance your security and user experience with our Face Recognition System. Say goodbye to passwords and hello to a safer, more user-friendly online environment. Contact us today to explore how our cutting-edge technology can benefit your website and users."
        }
      />
    </div>
  );
};

export default FaceRecognition;
