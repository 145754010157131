import React from "react";
import Carousel from "react-multi-carousel";
import { happyCustomersImages } from "../../../Common/Constant/itemLists";
import "react-multi-carousel/lib/styles.css";
import "./homePage.scss";

const HappyCustomers = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  return (
    <div className="customers-wrapper">
      <h1 className="customers-heading">Happy Customers</h1>
      <Carousel
        arrows={false}
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        showDots={false}
        transitionDuration={500}
        infinite={true}
      >
        {happyCustomersImages?.map((e) => {
          return (
            <div key={e.src} className="img-div">
              <img width={"160px"} height={"160px"} src={e.src} alt="" srcSet="" />
              <text>{e.name}</text>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HappyCustomers;
