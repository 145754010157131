import React from "react";
import "./smartCamera.scss";
import {
  smartCameraCarousel,
  smartCameraContent,
  smartCameraGridContent,
} from "../../../../Common/Constant/itemLists";
import LeftRightLayout from "../../../Layouts/left-right-layout/LeftRightLayout";
import PageIntroHeading from "../../../Layouts/PageIntroHeading/PageIntroHeading";
import MyGrid from "../../../Layouts/Grid/MyGrid";
import Conclusion from "../../../Layouts/Conclusion/Conclusion";
const SmartCamera = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"AI Camera"}
        subHeading={
          "Unlock the Power of AI for Your Security and Surveillance Needs"
        }
        text={
          "Are you ready to take your security and surveillance to the next level? Look no further! Our Smart AI Camera is your gateway to cutting-edge technology that revolutionizes the way you monitor and protect your home, business, and loved ones."
        }
      />
      <LeftRightLayout
        list={smartCameraContent}
        heading={"Our Product Range"}
        subHeading={"Explore our range of Smart AI cameras, including:"}
      />
      <MyGrid
        list={smartCameraGridContent}
        gridHeading={"Why Choose our Smart AI Camera?"}
      />
      <Conclusion
        heading={"Get Started Today."}
        text={
          "Join the large number of satisfied customers who have chosen Smart AI Camera to safeguard their assets and loved ones. Experience peace of mind like never before with our state-of-the-art AI-powered surveillance."
        }
      />
      <Conclusion
        heading={"Ready to get started?"}
        subHeading={
          "Contact our team today for a free consultation and find the perfect Smart AI camera solution for your needs. Your security is our priority, and we're here to assist you every step of the way."
        }
        text={
          "Don't wait - invest in the future of security with Smart AI Camera Service. Your protection, our innovation."
        }
        reverse={true}
      />
    </div>
  );
};

export default SmartCamera;
