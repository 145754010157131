import React from "react";
import LeftRightLayout from "../../Layouts/left-right-layout/LeftRightLayout";
import {
  webDevelopmentContent,
  webDevelopmentGridContent,
} from "../../../Common/Constant/itemLists";
import "./webDevelopment.scss";
import PageIntroHeading from "../../Layouts/PageIntroHeading/PageIntroHeading";
import MyGrid from "../../Layouts/Grid/MyGrid";
import Conclusion from "../../Layouts/Conclusion/Conclusion";
const WebDevelopment = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"Web Development"}
        subHeading={
          "In the digital age, a strong online presence is vital for success. Your website is often the first point of contact with potential customers, making it a critical element of your business strategy."
        }
        text={
          "At NDM.AI, we specialize in providing top-notch web development services that not only create stunning websites but also drive results. Whether you're a small business looking to establish an online presence or a large corporation in need of a website revamp, we have the expertise to bring your vision to life."
        }
      />
      <LeftRightLayout
        list={webDevelopmentContent}
        heading={"Our Web Development Services."}
      />
      <MyGrid
        list={webDevelopmentGridContent}
        gridHeading={"Why Choose NDM.AI?"}
      />
      <Conclusion
        heading={"Contact Us Today"}
        text={
          "Ready to take your online presence to the next level? Contact us today for a free consultation. Let's discuss your goals and how our web development services can help you achieve them. Together, we'll create a website that not only looks fantastic but also drives real results for your business."
        }
      />
    </div>
  );
};

export default WebDevelopment;
