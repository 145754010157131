import React from "react";
import LeftRightLayout from "../../Layouts/left-right-layout/LeftRightLayout";
import {
  mobileDevelopmentCarousel,
  mobileDevelopmentContent,
  mobileDevelopmentContentTwo,
  mobileDevelopmentGridContent,
} from "../../../Common/Constant/itemLists";
import "./mobileDevelopment.scss";
import PageIntroHeading from "../../Layouts/PageIntroHeading/PageIntroHeading";
import MyGrid from "../../Layouts/Grid/MyGrid";
import Conclusion from "../../Layouts/Conclusion/Conclusion";
import MyCarousel from "../../Layouts/MyCarousel/MyCarousel";
const MobileDevelopment = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"Mobile Development"}
        text={
          "Are you ready to turn your brilliant app idea into reality? Look no further! At NDM.AI, we specialize in crafting innovative and user-friendly mobile applications that captivate your audience and drive business growth. Our team of experienced developers and designers is here to transform your vision into a functional, stunning, and high-performing mobile app."
        }
      />
      <LeftRightLayout
        list={mobileDevelopmentContent}
        heading={"Our Mobile App Development Expertise"}
      />
      <MyGrid
        list={mobileDevelopmentGridContent}
        gridHeading={"Why Choose NDM.AI for Mobile App Development? "}
      />
      <MyCarousel
        heading={"Our Mobile App Development Expertise"}
        list={mobileDevelopmentCarousel}
      />
      <Conclusion
        heading={"Let's Bring Your App Idea to Life"}
        subHeading={
          "Are you excited to get started? Contact NDM.AI today, and let's embark on a journey to turn your mobile app dream into a reality. Whether you're a startup or an established business, we have the expertise to make your app stand out in the competitive mobile landscape.Don't miss the opportunity to connect with your target audience through a captivating mobile app. Get in touch with us today, and let's create something amazing together!"
        }
        text={
          "Feel free to customize this content to match your specific services and branding. Make sure to provide contact information and a call-to-action to encourage visitors to reach out to you for more information or a consultation."
        }
      />
    </div>
  );
};

export default MobileDevelopment;
