import React from "react";
import "./about.scss";
import { aboutUsContent } from "../../Common/Constant/itemLists";
import LeftRightLayout from "../Layouts/left-right-layout/LeftRightLayout";
const AboutUs = () => {
  return (
    // <>
    //   <div className="header">
    //     <h1>About Us</h1>
    //   </div>
    //   <div className="container">
    //     <div className="about-content">
    //       <div className="about-content-left">
    //         <h2>Our Company</h2>
    //         <p>
    //           Welcome to <strong>ndm.ai</strong>, your trusted partner in
    //           providing comprehensive solutions for the Internet of Things (IoT)
    //           and software development. With a passion for innovation and a
    //           commitment to excellence, we bring together cutting-edge
    //           technology and expert professionals to deliver the
    //           best-in-className solutions.
    //         </p>
    //       </div>
    //       <div className="about-content-right">Display Image </div>
    //     </div>

    //     <div className="our-clients">
    //       <div className="our-clients-left">
    //         <h2>Our Clients</h2>
    //         <p>
    //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
    //           molestias doloribus obcaecati illum accusantium esse iure magni
    //           totam veniam sit velit fugiat modi exercitationem maiores quaerat
    //           pariatur, itaque quae ipsam?
    //         </p>
    //       </div>
    //       <div className="our-clients-right">Display Image </div>
    //     </div>

    //     <div className="our-mission">
    //       <div className="our-mission-left">
    //         <h2>Our Mission</h2>
    //         <p>
    //           Our mission is to empower businesses and organizations to harness
    //           the potential of IoT and software technologies to enhance
    //           efficiency, productivity, and customer experiences. We strive to
    //           be a true partner, guiding our clients through every step of their
    //           digital transformation journey.
    //         </p>
    //       </div>
    //       <div className="our-mission-right">Display Image </div>
    //     </div>

    //     <div className="why-us">
    //       <div className="why-us-left">
    //         <h2>Why Choose Us</h2>
    //         <p>
    //           At <strong>ndm.ai</strong>, we stand out with:
    //         </p>
    //         <ul>
    //           <li>Extensive expertise in IoT and software development</li>
    //           <li>Customized solutions tailored to your unique needs</li>
    //           <li>A dedicated team of skilled professionals</li>
    //           <li>Proven track record of successful projects</li>
    //           <li>
    //             Commitment to quality, innovation, and customer satisfaction
    //           </li>
    //         </ul>
    //       </div>
    //       <div className="why-us-right">Display Image </div>
    //     </div>
    //   </div>
    // </>
    <div style={{ marginTop: "100px" }}>
      <LeftRightLayout list={aboutUsContent} heading={"About Us."} />
    </div>
  );
};

export default AboutUs;
