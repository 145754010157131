import React, { useRef } from "react";
import Background from "./components/Background";
import Services from "./components/Services";
import "./components/homePage.scss";
import HappyCustomers from "./components/HappyCustomers";
import ContactForm from "./components/ContactForm";
import Img from "../../Common/Core/Images/WE_DO_PNG.png";

const HomePage = (props) => {
  const background = useRef(null);

  return (
    <div>
      <Background background={background} />
      <img width={"100%"} src={Img} />
      <Services />
      <HappyCustomers />
      <ContactForm contactRef={props.contactRef} />
    </div>
  );
};

export default HomePage;
