import React from "react";
import "./pageIntroHeading.scss";
const PageIntroHeading = (props) => {
  return (
    <div className="page-intro-wrapper">
      <div
        className={`page-intro-container ${
          props.reverse === true ? "row-reverse" : ""
        }`}
      >
        <div className="page-intro-top">
          {props.heading ? <h2>{props.heading}</h2> : <></>}
        </div>
        <div className="page-intro-bottom">
          {props.subHeading ? <p>{props.subHeading}</p> : <></>}
          {props.text ? <p>{props.text}</p> : <></>}
        </div>
      </div>
    </div>
  );
};

export default PageIntroHeading;
