import React from "react";
import LeftRightLayout from "../../../Layouts/left-right-layout/LeftRightLayout";
import {
  aioSwitchCarousel,
  aioSwitchContent,
  aioSwitchGridContent,
} from "../../../../Common/Constant/itemLists";
import MyGrid from "../../../Layouts/Grid/MyGrid";
import MyCarousel from "../../../Layouts/MyCarousel/MyCarousel";
import "./aio.scss";
import Conclusion from "../../../Layouts/Conclusion/Conclusion";
import PageIntroHeading from "../../../Layouts/PageIntroHeading/PageIntroHeading";
const AIOSwitch = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"AioSwitch"}
        subHeading={
          "In today's fast-paced world, convenience and efficiency are paramount. Imagine having the power to control all your electric gadgets and appliances seamlessly with just a few taps on your smartphone or tablet. Welcome to the future of smart living with our cutting-edge Smart Infrastructure App."
        }
        text={
          "Smart infrastructure refers to the integration of advanced technologies into traditional infrastructure systems to enhance their functionality and efficiency. In the context of your website, it's about creating an ecosystem where electric gadgets and appliances in your home or office can be controlled remotely and intelligently"
        }
      />
      <LeftRightLayout
        list={aioSwitchContent}
        heading={"Features of our product."}
      />
      <MyGrid list={aioSwitchGridContent} gridHeading={"Why Choose Us?"} />
      <MyCarousel heading={"How It Works"} list={aioSwitchCarousel} />
      <Conclusion
        heading={"SO, what are you waiting for"}
        subHeading={
          "Make the leap into the future of smart living with our Smart Infrastructure App. Take control of your electric gadgets, enhance your energy efficiency, and enjoy the convenience of managing your devices from anywhere. With us, you're not just upgrading your infrastructure; you're upgrading your lifestyle."
        }
        text={
          "Contact us today to learn more and start your journey towards a smarter, more efficient, and more connected world."
        }
      />
    </div>
  );
};

export default AIOSwitch;
