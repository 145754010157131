import React from "react";
import "./footer.scss";
import ndm from "../icons/NDM_Logo.png";
import { Link } from "react-router-dom";
import Twitter from "../icons/Twitter";
import Instagram from "../icons/Instagram";
const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-cta pt-5 pb-5">
          <div className="row">
            <div className="row-container">
              <div className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span>current address</span>
                </div>
              </div>
            </div>
            <div className="row-container">
              <div className="single-cta">
                <i className="fas fa-phone"></i>
                <div className="cta-text">
                  <h4>Call us</h4>
                  <span>000-000-0000 </span>
                </div>
              </div>
            </div>
            <div className="row-container">
              <div className="single-cta">
                <i className="far fa-envelope-open"></i>
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>mail@info.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content">
          <div className="row">
            <div className="center-container">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={ndm} alt="logo" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>One Stop Solution To IOT & Software.</p>
                </div>
                <div className="footer-social-icon">
                  <span>Follow us</span>
                  <Link to="/about">
                    <Twitter />
                  </Link>
                  <Link tof="/">
                    <Instagram />
                  </Link>
                </div>
              </div>
            </div>
            <div className="center-container">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <Link to={"/"}>
                    <li>Home</li>
                  </Link>
                  <Link to={"/about"}>
                    <li>About</li>
                  </Link>
                  <Link to={"/"}>
                    <li>Services</li>
                  </Link>
                  <Link to={"/"}>
                    <li>Portfolio</li>
                  </Link>
                  <Link to={"/"}>
                    <li>Contact</li>
                  </Link>
                  <Link to={"/"}>
                    <li>About us</li>
                  </Link>
                  <Link to={"/"}>
                    <li>Our Services</li>
                  </Link>
                  <Link to={"/"}>
                    <li>Expert Team</li>
                  </Link>
                  <Link to={"/"}>
                    <li>Contact us</li>
                  </Link>
                  <Link to={"/"}>
                    <li>Latest News</li>
                  </Link>
                </ul>
              </div>
            </div>
            {/* <div className="center-container">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="footer-text mb-25">
                  <p>
                    Don’t miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </p>
                </div>
                <div className="subscribe-form">
                  <form
                    action={() => {
                      console.log("Hittitng");
                    }}
                  >
                    <input type="text" placeholder="Email Address" />
                    <button>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="bottom-container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>Copyright © 2023. All rights reserved.</p>
              </div>
            </div>
            <div className="">
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">Terms</a>
                  </li>
                  <li>
                    <a href="/">Privacy</a>
                  </li>
                  <li>
                    <a href="/">Policy</a>
                  </li>
                  <li>
                    <a href="/">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
