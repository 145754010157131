import React from "react";
import img from "../icons/NDM_Logo.png";
import { useNavigate } from "react-router-dom";
const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ width: "80px", cursor: "pointer" }}
      onClick={() => {
        navigate("/");
      }}
    >
      <img width={"100%"} src={img} alt="" srcSet="" />
    </div>
  );
};

export default Logo;
