import React from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import classNames from "classnames";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { products, services } from "../../Constant/itemLists";

export const NavigationBar = () => {
  const navigate = useNavigate();
  return (
    <NavigationMenu.Root className="NavigationMenuRoot">
      <NavigationMenu.List className="NavigationMenuList">
        <NavigationMenu.Item>
          <NavigationMenu.Link
            className="NavigationMenuLink"
            onClick={() => navigate("/")}
          >
            Home
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="NavigationMenuTrigger">
            Product
            <CaretDownIcon className="CaretDown" aria-hidden />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="NavigationMenuContent">
            <div className="hey">
              <ul className="List two">
                {products?.map((element) => {
                  return (
                    <div
                      key={element.heading + element.subHeading}
                      className="list-item-wrapper"
                    >
                      {element.heading}
                      <div className="list-button-wrapper">
                        {element.products?.map((e, i) => {
                          return (
                            <button
                              key={element.heading + i}
                              onClick={() => {
                                navigate(`/product/${e.path}`);
                              }}
                            >
                              <div>
                                <h4>{e.heading}</h4>
                                <p>{e.subHeading}</p>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                      {/* <ListItem
                      key={el.heading + el.subHeading}
                      title={el.heading}
                      onClick={() => navigate(`/product/${el.path}`)}
                    >
                      {el.subHeading}
                    </ListItem> */}
                    </div>
                  );
                })}
              </ul>
            </div>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="NavigationMenuTrigger">
            Services
            <CaretDownIcon className="CaretDown" aria-hidden />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="NavigationMenuContent">
            <ul className="List two">
              {services?.map((el) => {
                return (
                  <ListItem
                    key={el.heading + el.path}
                    // title={el.heading}
                    onClick={() => navigate(`/services/${el.path}`)}
                  >
                    <div className="services-div">
                      <h4>{el.heading}</h4>
                    </div>
                  </ListItem>
                );
              })}
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link
            className="NavigationMenuLink"
            onClick={() => navigate("/about")}
          >
            About
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Indicator className="NavigationMenuIndicator">
          <div className="Arrow" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className="ViewportPosition">
        <NavigationMenu.Viewport className="NavigationMenuViewport" />
      </div>
    </NavigationMenu.Root>
  );
};

const ListItem = React.forwardRef(
  ({ className, children, title, ...props }, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild>
        <a
          className={classNames("ListItemLink", className)}
          {...props}
          ref={forwardedRef}
        >
          <div className="ListItemHeading">{title}</div>
          <p className="ListItemText">{children}</p>
        </a>
      </NavigationMenu.Link>
    </li>
  )
);

export default NavigationBar;
