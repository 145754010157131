import React from "react";
import LeftRightLayout from "../../Layouts/left-right-layout/LeftRightLayout";
import {
  iotDevelopmentContent,
  iotDevelopmentGridContent,
} from "../../../Common/Constant/itemLists";
import PageIntroHeading from "../../Layouts/PageIntroHeading/PageIntroHeading";
import MyGrid from "../../Layouts/Grid/MyGrid";
import Conclusion from "../../Layouts/Conclusion/Conclusion";
import "./iotDevelopment.scss";
const IotDevelopment = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"IoT Development"}
        text={
          "Are you ready to harness the power of the Internet of Things (IoT) to transform your business and unlock new opportunities? At NDM.AI, we specialize in providing cutting-edge IoT solutions tailored to meet your unique needs and challenges. With our expertise in IoT technology, we can help you navigate the rapidly evolving landscape of connected devices, data analytics, and automation to drive efficiency, innovation, and growth."
        }
      />
      <LeftRightLayout
        list={iotDevelopmentContent}
        heading={"Why Choose Our IoT Solution Development Service?"}
      />
      <MyGrid
        list={iotDevelopmentGridContent}
        gridHeading={"Our IoT Solution Development Process"}
      />
      <Conclusion
        heading={"Unlock the Potential of IoT with NDM.AI"}
        subHeading={`Join the IoT revolution with NDM.AI and turn your data into actionable insights. Whether you want to optimize processes, enhance customer experiences, or create innovative products, our IoT solutions are your key to success. Contact us today to learn more about how we can help you harness the power of the Internet of Things.`}
        text={"Contact us at wecare@ndm.ai to get started on your IoT journey!"}
      />
    </div>
  );
};

export default IotDevelopment;
