import React from "react";
import PageIntroHeading from "../../Layouts/PageIntroHeading/PageIntroHeading";
import LeftRightLayout from "../../Layouts/left-right-layout/LeftRightLayout";
import MyGrid from "../../Layouts/Grid/MyGrid";
import Conclusion from "../../Layouts/Conclusion/Conclusion";
import "./mechanicalSupport.scss";
import {
  mechanicalSupportContent,
  mechanicalSupportGridContent,
} from "../../../Common/Constant/itemLists";

const MechanicalSupport = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"Mechanical Support Service"}
        text={
          "When it comes to ensuring the smooth operation of your mechanical systems and machinery, you need a trusted partner who understands the intricacies of your industry. Our Mechanical Support Service is here to provide you with comprehensive solutions, expert guidance, and top-notch support to keep your operations running efficiently."
        }
      />
      <LeftRightLayout
        list={mechanicalSupportContent}
        heading={"Our Services"}
      />
      <MyGrid
        list={mechanicalSupportGridContent}
        gridHeading={"Why Choose Our AI Solutions"}
      />
      <Conclusion
        heading={"Conclusion"}
        text={
          "Elevate your website to new heights with our AI Solution Development Service. Whether you want to enhance user engagement, automate tasks, or gain valuable insights from data, our team of AI experts is ready to turn your vision into reality. Contact us today to discuss how AI can transform your website and drive your business forward."
        }
      />
    </div>
  );
};

export default MechanicalSupport;
