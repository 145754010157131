import React, { useState } from "react";
import "./homePage.scss";

const ContactForm = (props) => {
  const [contactData, setContactData] = useState({
    name: "",
    lastName: "",
    mobile: "",
    company: "",
    email: "",
    message: "",
  });
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState(false);

  const isValidEmail = (email) => {
    return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  };

  const isValidMobile = (mobileno) => {
    return /^[6-9]\d{9}$/.test(mobileno);
  };

  const validateField = (field, value) => {
    if (value.length <= 0) {
      return (
        <>
          <span className="text-capitalize">{field}</span> is required field.
        </>
      );
    } else {
      if (field === "email") {
        if (!isValidEmail(value)) return "Invalid Email.";
      } else if (field === "mobile") {
        if (!isValidMobile(value)) return "Invalid Mobile Number.";
      } else {
        return "";
      }
    }
  };

  const handleBlur = (event) => {
    setErrorMsg(validateField(event.target.name, event.target.value));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactData({
      ...contactData,
      [name]: value,
    });

    if (name === "mobile") {
      setContactData({
        ...contactData,
        mobile: value.replace(/\D/, ""),
      });
    }
  };

  const handleSubmit = (e) => {
    let isValided = false;
    e.preventDefault();

    Object.keys(contactData).forEach((el) => {
      if (contactData[el] === "" || contactData[el] === undefined) {
        setSuccessMsg(false);
        isValided = false;
        setErrorMsg(el + " Field Cannot be empty");
        return;
      } else {
        setSuccessMsg(true);
        isValided = true;
        return;
      }
    });

    // if (!isValided) return; // returning

    if (
      contactData.name === "" ||
      contactData.name === undefined ||
      contactData.lastName === "" ||
      contactData.lastName === undefined ||
      contactData.mobile === "" ||
      contactData.mobile === undefined ||
      contactData.company === "" ||
      contactData.company === undefined ||
      contactData.email === "" ||
      contactData.email === undefined ||
      contactData.message === "" ||
      contactData.message === undefined
    ) {
      setSuccessMsg(false);
      isValided = false;
      // setErrorMsg("Field Cannot be empty");
      return;
    } else {
      setSuccessMsg(true);
      isValided = true;
    }

    return isValided;
  };

  return (
    <div id="contact" ref={props.contactRef}>
      <div className="form">
        <h1 className="title">Let's create Connection, let's talk!</h1>
        <p className="contact-sub-heading">
          You can reach us anytime via sales@ndm.ai
        </p>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          autoComplete="on"
          className="form-wrapper"
        >
          {!successMsg ? (
            <>
              <div className="form-group form-group-multiple">
                <div>
                  <label>First Name</label>
                  <input
                    placeholder="John"
                    name="name"
                    type="text"
                    className="form-control"
                    value={contactData.name || ""}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <input
                    placeholder="Doe"
                    name="lastName"
                    type="text"
                    className="form-control"
                    value={contactData.lastName || ""}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input
                  placeholder="+XX - XXXXX - XXXXX"
                  name="mobile"
                  type="number"
                  maxLength={10}
                  className="form-control"
                  // placeholder="Mobile"
                  onBlur={handleBlur}
                  value={contactData.mobile || ""}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label>Company</label>
                <input
                  placeholder="Great Company Private Limited"
                  name="company"
                  type="text"
                  className="form-control"
                  // placeholder="Company"
                  value={contactData.company || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  placeholder="johndoe@company.com"
                  name="email"
                  type="email"
                  className="form-control"
                  // placeholder="Email"
                  value={contactData.email || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group">
                <label>Your Message.</label>
                <textarea
                  placeholder="Hi, I would like to talk to you about a great idea..."
                  name="message"
                  type="text"
                  className="form-control"
                  // placeholder="Message"
                  value={contactData.message || ""}
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  rows="3"
                />
              </div>
              <div id="errormessage" className={errorMsg ? "show" : ""}>
                {errorMsg}
              </div>
              <p className="text-right mb-0">
                <input type="submit" className="submit" value="Submit Now" />
              </p>
            </>
          ) : (
            <div className="sendmessage">
              <text>Thank for your message. We will contact you soon.</text>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
