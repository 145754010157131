import React, { useEffect } from "react";
import { Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import videoFile from "../../../Common/Core/Video/bg.mov";
import "./homePage.scss";

const Background = (props) => {
  const heading = React.useRef();
  const subHeading = React.useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(heading.current, {
      scrollTrigger: {
        trigger: heading.current, // selector or element
        start: "top 200", // [trigger] [scroller] positions
        end: "+=300", // [trigger] [scroller] positions
        // markers: true,         // only during development!
        toggleActions: "restart pause reverse reverse",
        // toggleActions: "play none reverse none",
        scrub: true,
      },
      delay: 1,
      x: 0,
      y: 100,
      ease: Power3.easeOut,
    });
  }, []);

  // useEffect(() => {
  //   if (!vantaEffect) {
  //     setVantaEffect(
  //       TRUNK({
  //         el: props.background.current,
  //         mouseControls: false,
  //         touchControls: false,
  //         gyroControls: false,
  //         backgroundColor: "black",
  //         color: "#2a6cdf40",
  //         minHeight: 200.0,
  //         minWidth: 200.0,
  //         scale: 1.0,
  //         scaleMobile: 1.0,
  //         spacing: 20.0,
  //         chaos: 8,
  //       })
  //     );
  //   }
  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy();
  //   };
  // }, [vantaEffect, props.background]);

  return (
    <div className="video-background vanta-background" ref={props.background}>
      <video autoPlay={true} muted={true} loop={true}>
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={"sub-heading scroll-container"}>
        <div className="scroll-text">
          Seeking Expertise in Navigating the World of AI, IoT, Web 3.0, and
          Industry 4.0 Solutions? A Global Network, Ready to Help 24/7 We're
          Eager to Hear from You – Contact Us
        </div>
      </div>
      <div>
        <h1 ref={heading} className="heading">
          Unleash the
          <br />
          Potential of Tomorrow
        </h1>
      </div>
      <div className="circle-wrapper">
        <div className="circle">
          <div className="circle-content">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M11 21V19.9291C7.60771 19.4439 5 16.5265 5 13V8C5 4.13401 8.13401 1 12 1C15.866 1 19 4.13401 19 8V13C19 16.5265 16.3923 19.4439 13 19.9291V21H17V23H7V21H11ZM12 3C9.23858 3 7 5.23858 7 8V13C7 15.7614 9.23858 18 12 18C14.7614 18 17 15.7614 17 13V8C17 5.23858 14.7614 3 12 3ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9ZM12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11Z"></path>
            </svg>
          </div>
        </div>
        <div className="circle">
          <div className="circle-content">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M4 16H20V5H4V16ZM13 18V20H17V22H7V20H11V18H2.9918C2.44405 18 2 17.5511 2 16.9925V4.00748C2 3.45107 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44892 22 4.00748V16.9925C22 17.5489 21.5447 18 21.0082 18H13Z"></path>
            </svg>
          </div>
        </div>
        <div className="circle">
          <div className="circle-content">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M9 4C10.1046 4 11 4.89543 11 6V12.8271C10.1058 12.1373 8.96602 11.7305 7.6644 11.5136L7.3356 13.4864C8.71622 13.7165 9.59743 14.1528 10.1402 14.7408C10.67 15.3147 11 16.167 11 17.5C11 18.8807 9.88071 20 8.5 20C7.11929 20 6 18.8807 6 17.5V17.1493C6.43007 17.2926 6.87634 17.4099 7.3356 17.4864L7.6644 15.5136C6.92149 15.3898 6.1752 15.1144 5.42909 14.7599C4.58157 14.3573 4 13.499 4 12.5C4 11.6653 4.20761 11.0085 4.55874 10.5257C4.90441 10.0504 5.4419 9.6703 6.24254 9.47014L7 9.28078V6C7 4.89543 7.89543 4 9 4ZM12 3.35418C11.2671 2.52376 10.1947 2 9 2C6.79086 2 5 3.79086 5 6V7.77422C4.14895 8.11644 3.45143 8.64785 2.94126 9.34933C2.29239 10.2415 2 11.3347 2 12.5C2 14.0652 2.79565 15.4367 4 16.2422V17.5C4 19.9853 6.01472 22 8.5 22C9.91363 22 11.175 21.3482 12 20.3287C12.825 21.3482 14.0864 22 15.5 22C17.9853 22 20 19.9853 20 17.5V16.2422C21.2044 15.4367 22 14.0652 22 12.5C22 11.3347 21.7076 10.2415 21.0587 9.34933C20.5486 8.64785 19.8511 8.11644 19 7.77422V6C19 3.79086 17.2091 2 15 2C13.8053 2 12.7329 2.52376 12 3.35418ZM18 17.1493V17.5C18 18.8807 16.8807 20 15.5 20C14.1193 20 13 18.8807 13 17.5C13 16.167 13.33 15.3147 13.8598 14.7408C14.4026 14.1528 15.2838 13.7165 16.6644 13.4864L16.3356 11.5136C15.034 11.7305 13.8942 12.1373 13 12.8271V6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6V9.28078L17.7575 9.47014C18.5581 9.6703 19.0956 10.0504 19.4413 10.5257C19.7924 11.0085 20 11.6653 20 12.5C20 13.499 19.4184 14.3573 18.5709 14.7599C17.8248 15.1144 17.0785 15.3898 16.3356 15.5136L16.6644 17.4864C17.1237 17.4099 17.5699 17.2926 18 17.1493Z"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Background;
