import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./contactButton.scss";

const ContactButton = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <button
      className={"contact-button"}
      onClick={() => {
        if (location.pathname === "/") {
          props?.contactRef?.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        } else {
          navigate("/");
          setTimeout(() => {
            props?.contactRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }, 10);
        }
      }}
    >
      Contact
    </button>
  );
};

export default ContactButton;
