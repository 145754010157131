import React from "react";
import {
  kiosk,
  kioskCarousel,
  kioskContent,
  kioskGridContent,
} from "../../../../Common/Constant/itemLists";
import LeftRightLayout from "../../../Layouts/left-right-layout/LeftRightLayout";
import "./kiosk.scss";
import PageIntroHeading from "../../../Layouts/PageIntroHeading/PageIntroHeading";
import MyGrid from "../../../Layouts/Grid/MyGrid";
import MyCarousel from "../../../Layouts/MyCarousel/MyCarousel";
import Conclusion from "../../../Layouts/Conclusion/Conclusion";
const Kiosk = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"Kiosk"}
        text={
          "Are you looking for a convenient and efficient way to provide information, services, or products to your customers? Look no further than our cutting-edge Kiosk Service! Whether you're a business owner, event organizer, or simply someone who wants to streamline customer interactions, our Kiosk Service is the perfect solution."
        }
      />
      <PageIntroHeading
        heading={"About our product “Kiosk”"}
        text={
          "A Kiosk is a self-service, touch-screen interface that allows users to interact with digital content or complete specific tasks. These user-friendly terminals can be placed in various locations, including retail stores, airports, hotels, healthcare facilities, museums, and more. With our Kiosk, you can enhance user experience, reduce wait times, and boost overall customer satisfaction."
        }
        reverse={true}
      />
      <LeftRightLayout
        list={kioskContent}
        heading={"Key Features of Our Kiosk"}
      />
      <MyGrid
        list={kioskGridContent}
        gridHeading={"Benefits of Implementing Our Kiosk"}
      />
      <MyCarousel heading={"Industries We Serve."} list={kioskCarousel} />
      <Conclusion
        heading={"Why Choose Our Kiosk?"}
        subHeading={
          "At NDM.AI, we have a proven track record of delivering reliable and customizable kiosk. Our team of experts is dedicated to helping you enhance customer experiences, increase efficiency, and drive business growth."
        }
        text={
          "Ready to take your business or organization to the next level with our Kiosk? Contact us today to discuss your specific needs and discover how our innovative technology can benefit you!"
        }
      />
    </div>
  );
};

export default Kiosk;
