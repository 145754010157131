import React from "react";
import { NavigationBar } from "../NavigationMenu/NavigationBar";
import ContactButton from "../Button/ContactButton/ContactButton";
import Logo from "../Logo/Logo";
import "./menubar.scss";
export const Menubar = (props) => {
  return (
    <div className="menu-bar">
      <div className="menu-content">
        <Logo />
        <NavigationBar />
        <ContactButton contactRef={props.contactRef}/>
      </div>
    </div>
  );
};
