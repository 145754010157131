import React from "react";
import "./grid.scss";
const MyGrid = (props) => {
  return (
    <div className={`grid-wrapper`}>
      <div className="grid-wrapper-text">
        {props.gridHeading ? <h1>{props.gridHeading}</h1> : <></>}
        {props.gridSubHeading ? <p>{props.gridSubHeading}</p> : <></>}
      </div>
      <div className={`grid-container ${props.custom ? "grid-2":""}`}>
        {props.list?.map((element, index) => {
          return (
            <div
              key={Math.random().toString(36).slice(2)}
              className={`grid-content`}
            >
              {element.image ? (
                <div className={`grid-content-img`}>
                  <img
                    width={"20%"}
                    height={"60px"}
                    src={element.image}
                    alt=""
                    srcSet=""
                  />
                </div>
              ) : (
                <></>
              )}
              <div className={`grid-content-text`}>
                {element.heading ? <h2>{element.heading}</h2> : <></>}
                {element.subHeading ? <p>{element.subHeading}</p> : <></>}
                {element.text ? <p>{element.text}</p> : <></>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyGrid;
