import React from "react";
import "./conclusion.scss";
const Conclusion = (props) => {
  return (
    <div className="conclusion-wrapper">
      <div
        className={`conclusion-container ${
          props.reverse === true ? "row-reverse" : ""
        }`}
      >
        <div className="conclusion-top">
          {props.heading ? <h2>{props.heading}</h2> : <></>}
        </div>
        <div className="conclusion-bottom">
          {props.subHeading ? <p>{props.subHeading}</p> : <></>}
          {props.text ? <p>{props.text}</p> : <></>}
        </div>
      </div>
    </div>
  );
};

export default Conclusion;
