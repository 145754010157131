import React from "react";
import {
  podium,
  podiumContent,
  podiumGridContent,
} from "../../../../Common/Constant/itemLists";
import LeftRightLayout from "../../../Layouts/left-right-layout/LeftRightLayout";
import "./podium.scss";
import PageIntroHeading from "../../../Layouts/PageIntroHeading/PageIntroHeading";
import MyGrid from "../../../Layouts/Grid/MyGrid";
import MyCarousel from "../../../Layouts/MyCarousel/MyCarousel";
import Conclusion from "../../../Layouts/Conclusion/Conclusion";
const Podium = () => {
  return (
    <div>
      <PageIntroHeading
        heading={"Podium"}
        subHeading={
          "Introduction:  Podium – Your Trusted Partner for Premium Audio-Visual Solutions"
        }
        text={
          "With Podium, we are committed to providing cutting-edge audio-visual solutions to elevate your presentations, events, and communication experiences. Whether you're a business, educational institution, or event organizer, our comprehensive range of podium hardware services will ensure that your message is heard and seen with clarity, impact, and professionalism."
        }
      />
      <LeftRightLayout list={podiumContent} heading={"Our Offerings"} />
      <MyGrid list={podiumGridContent} gridHeading={"Why Choose Podium?"} />
      <Conclusion
        heading={"Conclusion."}
        subHeading={
          "With Podium, we believe that powerful communication begins with the right hardware. Trust us to provide you with the tools you need to make a lasting impact. Elevate your presentations, lectures, and events with our premium audio-visual solutions."
        }
        text={
          "Contact us today to explore how we can enhance your communication experiences. Your podium, your message, our commitment to excellence."
        }
      />
    </div>
  );
};

export default Podium;
