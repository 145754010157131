import React from "react";
import "./services.scss"
const Services = () => {
  return (
    <>
      <div className="header">
        <h1>Services</h1>
      </div>
      <div className="service-page-container">
        <div className="services-content">
          <div className="services-content-left">
            <h2>Our Services</h2>
            <p>
              At <strong>ndm.ai</strong>, we offer a range of comprehensive
              services designed to empower your business with cutting-edge
              technology:
            </p>

            <h3>Internet of Things (IoT)</h3>
            <p>
              Our IoT solutions enable you to connect and manage devices,
              collect data, and gain valuable insights to optimize processes and
              enhance efficiency.
            </p>

            <h3>Artificial Intelligence (AI)</h3>
            <p>
              Harness the power of AI to analyze data, automate tasks, and make
              data-driven decisions that drive innovation and deliver tangible
              results.
            </p>
          </div>
          <div className="services-content-right">Images</div>
        </div>
        <div className="work-content">
          <div className="work-content-left">
            <h2>Our Services</h2>
            <p>
              At <strong>ndm.ai</strong>, we offer a range of comprehensive
              services designed to empower your business with cutting-edge
              technology:
            </p>

            <h3>Internet of Things (IoT)</h3>
            <p>
              Our IoT solutions enable you to connect and manage devices,
              collect data, and gain valuable insights to optimize processes and
              enhance efficiency.
            </p>

            <h3>Artificial Intelligence (AI)</h3>
            <p>
              Harness the power of AI to analyze data, automate tasks, and make
              data-driven decisions that drive innovation and deliver tangible
              results.
            </p>
          </div>
          <div className="work-content-right">Images</div>
        </div>
      </div>
    </>
  );
};

export default Services;
