import Routing from "./Components/Routing";
import { Menubar } from "../src/Common/Core/Menubar/Menubar";
import Footer from "./Common/Core/Footer/Footer";
import "./App.scss";
import React from "react";

function App() {
  const contactRef = React.useRef(null);
  return (
    <div className="app-container"  >
      <Menubar contactRef={contactRef} />
      <Routing contactRef={contactRef} />
      <Footer />
    </div>
  );
}

export default App;
