import React from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./leftRight.scss";

const LeftRightLayout = (props) => {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let pinGroups = gsap.utils.toArray(".left-right-container");
    const pinGroupContent = gsap.utils.toArray(".left-right-container > *");

    pinGroups.forEach((pingroup) => {
      ScrollTrigger.create({
        trigger: pingroup,
        start: "center center",
        end: "+=" + window.innerHeight / 2,
        // pin: true
      });
    });

    gsap.set(pinGroupContent, {
      opacity: 0.5,
      y: 100,
    });

    pinGroupContent.forEach((e, i) => {
      ScrollTrigger.create({
        trigger: e,
        start: "center 80%",
        end: "bottom 40%",
        onEnter: () => gsap.to(e, { x: 0, y: 0, opacity: 1, duration: 0.4 }),
        onEnterBack: () => gsap.to(e, { y: 0, opacity: 1, duration: 0.4 }),
        onLeave: () =>
          gsap.to(e, {
            y: -100,
            opacity: 0,
            duration: 0.4,
          }),
        onLeaveBack: () =>
          gsap.to(e, {
            x: i % 2 === 0 ? -100 : 100,
            y: 100,
            opacity: 0,
            duration: 0.4,
          }),
        toggleActions: "restart pause reverse reverse",
        scrub: true,
        // markers: true,
      });
    });
  }, []);

  return (
    <div className="left-right-container">
      {props.heading ? (
        <h2 style={{ textAlign: "center" }}>{props.heading}</h2>
      ) : (
        <></>
      )}
      {props.list?.map((element, index) => {
        return (
          <div
            key={Math.random().toString(36).slice(2)}
            className={`left-right-content ${
              index % 2 === 0 ? "left-right-row" : "left-right-row-reverse"
            }`}
          >
            <div className="content-left">
              {element.heading ? <h2>{element.heading}</h2> : <></>}
              {element.subHeading ? <p>{element.subHeading}</p> : <></>}
              {element.text ? <text>{element.text}</text> : <></>}
            </div>
            <div className="content-right">
              <img width={"100%"} src={element.image} alt="" srcSet="" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LeftRightLayout;
